import {Outlet, Link, useNavigate} from 'react-router-dom';
import Button, { ButtonType } from '../components/Button/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getUser, getToken, setUserSession, resetUserSession } from '../service/AuthService';
import { verify } from '../service/FetchService';
import { useState, useEffect } from 'react';

export default function Layout() {

  const [isDark, setIsDark] = useState(true);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem("ACT_DARK_MODE");

    if (storedDarkMode) {
      setIsDark(storedDarkMode === "true");
    }

    if (isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDark]); 
  
  function toggleDarkMode() {

    let newDarkValue : boolean = !isDark;

    setIsDark(newDarkValue);
    localStorage.setItem("ACT_DARK_MODE", newDarkValue.toString());
  }

  const [isVisible, setIsVisible] = useState(false);
  const [hasValidToken, setHasValidToken] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = getToken();
    if (token === 'undefined' || token === undefined || token === null || !token) {
      navigate('/');
      return;
    }

    const user = getUser();
    const requestBody = {
      user: user,
      token: token
    };
    const fetchVerify = async () => {
      const response = await verify(JSON.stringify(requestBody));
      if (!response.ok) {
        setHasValidToken(false);
        resetUserSession();
        navigate('/');
      }
      const json = await response.json();
      setUserSession(json.user, json.token);
      setHasValidToken(true);
      setIsVisible(true);
    };

    fetchVerify();
  }, [hasValidToken, isVisible]);


  function handleLogout() {
    resetUserSession();
    navigate(0);
  }

  return (
    <>
      { (hasValidToken) ?
        <>
        <Navbar expand="lg" className="bg-body-tertiary"
        bg={(isDark) ? "dark" : "light"} data-bs-theme={(isDark) ? "dark" : "light"}>
          <Container>
            <Navbar.Brand href="/">AC Tool</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <NavDropdown title="Dev Tools" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/BuildCMD">Build CMD</NavDropdown.Item>
                  <NavDropdown.Item href="/UrlShortener">Url Shortener</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Cryptography Tools" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/Steg">Steganography</NavDropdown.Item>
                  <NavDropdown.Item href="/CesarianShift">Cesarian Shift</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/PGP">PGP: My Public Key</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
              <div className="justify-content-end">
                <NavDropdown title="Settings" id="profile-nav-dropdown">
                  <NavDropdown.Item onClick={toggleDarkMode}>{(isDark) ? "Light Mode" : "Dark Mode"}</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
          </Container>
        </Navbar>
          <Outlet /> 
          </>
        :
        <>
          <Navbar expand="lg" className="bg-body-tertiary"
            bg={(isDark) ? "dark" : "light"} data-bs-theme={(isDark) ? "dark" : "light"}>
            <Container>
              <Navbar.Brand href="/">AC Tool</Navbar.Brand>
              <div className="justify-content-end">
                <Nav.Link onClick={toggleDarkMode}>{(isDark) ? "Light Mode" : "Dark Mode"}</Nav.Link>
              </div>
            </Container>
          </Navbar>
          <Outlet />
        </>
      }
    </>
  )
}
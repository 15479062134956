import { JobExperience, JobTask } from './Types';

const startsWithJavaRegex: RegExp = /^java-/i;
const startsWithPythonRegex: RegExp = /^python-/i;

const pythonOrderRegex: RegExp = /python-(\d+)/;
const javaOrderRegex: RegExp = /java-(\d+)/;

type TaskFilterFunction = (tasks: JobTask[]) => JobTask[];

export function prioritizeJavaTasks(jobList: JobExperience[]): JobExperience[] {
  const updatedJobList = jobList.map(job => {
    const updatedTasks = reorderTasksAscending([...job.tasks], javaOrderRegex);
    return { ...job, tasks: updatedTasks };
  });
  return updatedJobList;
}

export function prioritizePythonTasks(jobList: JobExperience[]): JobExperience[] {
  const updatedJobList = jobList.map(job => {
    const updatedTasks = reorderTasksAscending([...job.tasks], pythonOrderRegex);
    return { ...job, tasks: updatedTasks };
  });
  return updatedJobList;
}

export function hidePythonTasks(jobList: JobExperience[]): JobExperience[] {
  return updateTasksInJobList(jobList, filterPythonTasks);
}

export function hideJavaTasks(jobList: JobExperience[]): JobExperience[] {
  return updateTasksInJobList(jobList, filterJavaTasks);
}

export function showAllTasks(jobList: JobExperience[]): JobExperience[] {
  return updateTasksInJobList(jobList, removeTaskFilter);
}

function removeTaskFilter(tasks: JobTask[]) {
  const updatedTasks = tasks.map(task => {
    return { ...task, isVisible: true };
  });
  return updatedTasks;
}

function filterPythonTasks(tasks: JobTask[]) {
  const updatedTasks = tasks.map((task: JobTask) => {
    if (task.tags?.some((str: string) => startsWithPythonRegex.test(str))) {
      return { ...task, isVisible: false };
    }
    return task;
  });
  return updatedTasks;
}

function filterJavaTasks(tasks: JobTask[]) {
  const updatedTasks = tasks.map((task: JobTask) => {
    if (task.tags?.some((str: string) => startsWithJavaRegex.test(str))) {
      return { ...task, isVisible: false };
    }
    return task;
  });
  return updatedTasks;
}

function updateTasksInJobList(jobList: JobExperience[], taskFilterFunction: TaskFilterFunction): JobExperience[] {
  const updatedJobList: JobExperience[] = jobList.map( (job: JobExperience) => {
    const updatedTasks = taskFilterFunction(job.tasks);
    return { ...job, tasks: updatedTasks };
  });
  return updatedJobList;
}

function reorderTasksAscending(tasks: JobTask[], matchRegex: RegExp): JobTask[] {
  return tasks.slice().sort((taskA: JobTask, taskB: JobTask) => {
    const numberA = (taskA && taskA.tags && taskA.tags.length > 0) ? extractNumberFromTag(taskA, matchRegex) : Infinity;
    const numberB = (taskB && taskB.tags && taskB.tags.length > 0) ? extractNumberFromTag(taskB, matchRegex) : Infinity;
    return numberA - numberB;
  });
}

function extractNumberFromTag(task: JobTask, matchRegex: RegExp): number {
  const tags = task.tags;
  const matchIndex = (tags?.some(tag => matchRegex.test(tag))) ? tags?.findIndex(tag => matchRegex.test(tag)) : null;
  const match = (matchIndex != null && (matchIndex >= 0) && tags) ? tags[matchIndex].split('-')[1] : null;
  const order = match ? parseInt(match) : Infinity;
  return order;
}
import {useState} from 'react';
import './ToDoList.css';
import Button, { ButtonType } from '../Button/Button';
import ButtonGroup from '../ButtonGroup/ButtonGroup';


export default function ToDoList(){

  let TEST_DATA = [
    {
      id: 1700074665900,
      title: "Practice React",
      description: "Create react apps to prepare for the hacker challenge",
      isDone: false
    }
  ]

  const [listData, setListData] = useState(TEST_DATA);

  const renderToDoItem = (id:number, title: string, description: string, isDone: boolean) => {

    return (
    <div key={`toDo${id}`} className="toDoRow">
      <input type="text" value={title} onChange={e => handleTitleChange(id, e.target.value)} className={(isDone) ? "strikeThrough" : ""}></input>
      <ButtonGroup>
        <Button click={() => handleToggleDone(id, isDone)} text='Done' type={ButtonType.SUCCESS}/>
        <Button click={() => handleDelete(id)} text='Delete' type={ButtonType.DANGER}/>
      </ButtonGroup>
    </div>
    )
  }

  function handleAddRowClick(){
    setListData(() => listData.concat(
      {id: new Date().getTime(), title: "New Item", description: "", isDone: false}
      )
    )
  }

  function handleDebug(){
    console.log('debug', listData);
  }

  function handleTitleChange(id:number, title: string){
    let updatedArray = listData.map(data => {
      if(data.id == id){
        return {...data, title: title}
      }
      return data;
    });
    setListData(updatedArray);
  }

  function handleToggleDone(id:number, isDone: boolean){
    const newValue: boolean = !isDone;
    let updatedArray = listData.map(data => {
      if(data.id == id){
        return {...data, isDone: newValue}
      }
      return data;
    });
    setListData(updatedArray);
  }

  function handleDelete(id:number) {
    setListData( (data) => {return data.filter( (datum) => datum.id != id )})
  }

  return (
    <>
      <section className="toDoList">
        {listData.map( data => (
          renderToDoItem(data.id, data.title, data.description, data.isDone)
        )
        )}
      </section>
      <button onClick={handleAddRowClick}>Add New Row</button>
    </>
  )
}
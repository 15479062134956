const verifyUrl = 'https://co2qd1mi8f.execute-api.us-east-1.amazonaws.com/prod/verify';
const loginUrl = 'https://co2qd1mi8f.execute-api.us-east-1.amazonaws.com/prod/login';
const shortenUrl = 'https://9sur4oyf00.execute-api.us-east-1.amazonaws.com/prod/createShortUrl';

const comp = '9HsB4nOd7XaNuAlLsyEwj8aOsgVmhaXw4T2KDFEH';

export async function login(fetchBody: string) {
  return await fetchData(loginUrl, "POST", fetchBody);
}

export async function verify(fetchBody: string) {
  return await fetchData(verifyUrl, "POST", fetchBody);
}

export type ShortUrlJsonBody = {
  url_id: string,
  url: string,
  owner:  string
}

export async function fetchShortUrl(fetchBody: string, apiKey: string) {
  return await fetchData(shortenUrl, "POST", fetchBody, apiKey);
}

async function fetchData(url: string, method: string, body: string, apiKey: string = comp): Promise<Response> {
  return await fetch(url, {
    method: method,
    headers: {
      'Content-Type': "application/json",
      'x-api-key': apiKey // I know... static website compromise
    },
    body: body
  });
}
import ToDoList from '../components/ToDoList/ToDoList';
import Banner from '../components/Banner/Banner';

export default function ToDo(){
  return (
    <>
      <Banner title="To Do List" text="Create a list of things to do, then mark them as complete."/>
      <ToDoList />
    </>
  )
}
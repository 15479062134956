import { ReactChild, ReactNode, ReactText, Children } from 'react';
import './ButtonGroup.css';

type props = {
  children: ReactNode
}

export default function ButtonGroup({children} : props){

  function debug(){
    Children.map(children, (child: ReactNode) => console.log(child));
  }

  return (


    <div className='btnGroup'>
      { children }
    </div>
  )

}
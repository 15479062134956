import {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Layout from './pages/Layout';
import Home from './pages/Home';
import ToDo from './pages/ToDo';
import Steg from './pages/Steg';
import PGP from './pages/PGP';
import CesarianShift from './pages/CesarianShift';
import BuildCmd from './pages/BuildCmd';
import ResumeBuilder from './pages/ResumeBuilder';
import Projects from './pages/Projects';
import UrlShortener from './pages/UrlShortener';
import './bootstrap-icons/bootstrap-icons.css';


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Layout/>}>
            <Route index element={<Home/>}/>
            <Route path="todo" element={<ToDo/>}/>
            <Route path="Steg" element={<Steg/>}/>
            <Route path="PGP" element={<PGP/>}/>
            <Route path="BuildCmd" element={<BuildCmd/>}/>
            <Route path="CesarianShift" element={<CesarianShift/>}/>
            <Route path="ResumeBuilder" element={<ResumeBuilder/>}/>
            <Route path="Projects" element={<Projects/>}/>
            <Route path="UrlShortener" element={<UrlShortener/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

"use client";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import styles from './CmdSaved.module.css';

interface cmdSavedProps {
  onDeleteSavedCmd: any,
  title: string,
}

export default function CmdSaved(props: cmdSavedProps) {


  function copySavedCmd(){
    let text = props.title;
    navigator.clipboard.writeText(text);
  }

  function deleteSavedCmd(){
    props.onDeleteSavedCmd(props.title);
  }

  return (
    <div style={{ backgroundColor: 'inherit' }}>
      <Row style={{ backgroundColor: 'inherit' }}>
        <Col xs={10} style={{ backgroundColor: 'inherit'}}>
          <div style={{ backgroundColor: 'inherit'}}><span className={styles.cmdTitle}>{props.title}</span>
            <Button variant='outline-info' onClick={() => copySavedCmd()} tabIndex={-1} size='sm'><i className="bi bi-copy"></i></Button>
          </div>
        </Col>
        <Col xs={2} style={{ backgroundColor: 'inherit'}}>
          <Button variant='outline-danger' onClick={() => deleteSavedCmd()} tabIndex={-1} size='sm'><i className="bi bi-trash3-fill" /></Button>
        </Col>
      </Row>
    </div>
  )
}
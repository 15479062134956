import { JobExperience, JobTask } from './Types';

// Could be in a db, but it's here for now.

let tdaJob: JobExperience = {
  company: "Technical Data Analysis, Inc.",
  title: "Software Developer",
  location: "Falls Church, VA",
  startDate: "November 2014",
  endDate: "January 2017",
  tasks: [
    {
      description: "Led the development of the record tracking web app with Spring MVC built Gradle",
      tags: ["java-1", "spring-1", "mvc-1", "gradle-1", "web_dev-1"],
      isVisible: true
    },
    {
      description: "Created User Interface with HTML5 and CSS with jQuery and AJAX to display information",
      tags: ["javascript-1", "jquery-1", "ajax-1", "html-1", "css-1", "userinterface-1", "web_dev-1"],
      isVisible: true
    },
    {
      description: "Developed the part-maintenance application using Struts2 MVC while providing support for current live users",
      tags: ["struts-1", "mvc-1", "support-1", "web_dev-2", "java-3"],
      isVisible: true
    },
    {
      description: "Added new UI features with various JSPs using HTML, JavaScript, CSS, and JSTL",
      tags: ["javascript-2", "jsp-1", "jstl-1", "html-2", "css-2", "userinterface-2", "web_dev-1", "java-4"],
      isVisible: true
    },
    {
      description: "Created dynamic interfaces using AJAX",
      tags: ["userinterface-3", "web_dev-3", "ajax-2", "javascript-3"],
      isVisible: true
    },
    {
      description: "Updated legacy code to more efficient and safe code",
      tags: ['refactor-1', "web_dev-4"],
      isVisible: true
    },
    {
      description: "Involved in many transitions to newer and better tools and frameworks such as React, HQL, and Maven",
      tags: ['java-5', "javascript-4", "hql-1", "maven-1", "react-1", "refactor-2"],
      isVisible: true
    },
    {
      description: "Converted front end from jQuery to React components",
      tags: ['javascript-5', "jquery-2", "react-2"],
      isVisible: true
    },
    {
      description: "Managed and deployed builds to JBoss and Tomcat servers on local, test, and live sites",
      tags: ['java-6', "jboss-1", "tomcat-1", "apache-1", "architecture-1"],
      isVisible: true
    }],
  isVisible: true
};

let probityJob_one: JobExperience = {
  company: "Probity, Inc.",
  title: "Software Developer",
  location: "Herndon, VA",
  startDate: "January 2017",
  endDate: "July 2019",
  tasks: [
    {
      description: "Developed the desktop application using NW.js, Node.js, JavaScript, HTML5/CSS",
      tags: ["desktop_app-1", "javascript-1", "mvc-1", "nwjs-1", "web_dev-1", "nodejs-1", "html-1", "css-1"],
      isVisible: true
    },
    {
      description: "Implemented multi-window communication using asynchronous practices using Promises, callbacks, and async/await",
      tags: ["javascript-2", "promises-1", "async-1", "userinterface-1", "web_dev-2"],
      isVisible: true
    },
    {
      description: "Integrated Audio Processing with importing LibAV and FFMPEG into Node using node-gyp",
      tags: ["node_gyp-1"],
      isVisible: true
    },
    {
      description: "Managed and maintained builds and releases",
      tags: ["sdlc-1", "dev_ops-1"],
      isVisible: true
    },
    {
      description: "Implemented an internal test API using Python and Flask",
      tags: ["python-1", "api-1", "flask-1"],
      isVisible: true
    },
    {
      description: "Researched and implemented new technologies into the application",
      tags: ['refactor-1', "web_dev-4"],
      isVisible: true
    },
    {
      description: "Refactored legacy code and redesigned application structure",
      tags: ["javascript-3", "refactor-2"],
      isVisible: true
    },
    {
      description: "Designed and developed a more modern and efficient GUI",
      tags: ['javascript-4', "react-2", "userinterface-2"],
      isVisible: true
    },
    {
      description: "Updated the app to be compatible on Windows and UNIX systems",
      tags: ['javascript-5', "architecture-1"],
      isVisible: true
    },
    {
      description: "Created font-icons and icons using svg and canvas",
      tags: ["design-1", "userinterfac-3"],
      isVisible: true
    }],
  isVisible: true
};

let solutionsPestAndLawn: JobExperience = {
  company: "Solutions Pest and Lawn",
  title: "Freelance Software Developer (Remote - Part Time Contract)",
  location: "Houston, TX",
  startDate: "March 2019",
  endDate: "April 2019",
  tasks: [
    {
      description: " Architected and implemented the company's Single Page Application (SPA) for streamlined service booking. Leveraged MySQL, Express.js, Angular, and Node.js, ensuring a robust and user-friendly booking platform",
      tags: ["spa-1", "javascript-1", "expressjs-1", "angular-1", "web_dev-1", "nodejs-1", "html-1", "css-1", "mysql-1"],
      isVisible: true
    },
    {
      description: "Orchestrated the creation of a RESTful API using Express and Node, integrating with both internal databases and external APIs such as Zillow, Google, and Stripe",
      tags: ["javascript-2", "api-1", "expressjs-2", "nodejs-2", "web_dev-2", "rest-1"],
      isVisible: true
    },
    {
      description: "Elevated the company's online presence through improved Search Engine Optimization(SEO) with Server-Side Rendering(SSR) and Angular Universal",
      tags: ["seo-1", "ssr-1", "angular-2"],
      isVisible: true
    },
    {
      description: "Implemented a comprehensive system for recording payment transactions and service activities using MySQL and Nodemailer",
      tags: ["mysql-2", "nodemailer-1"],
      isVisible: true
    }],
  isVisible: true
};

let jpmc: JobExperience = {
  company: "JP Morgan and Chase",
  title: "Software Developer",
  location: "Houston, TX",
  startDate: "March 2019",
  endDate: "April 2019",
  tasks: [
    {
      description: "Improved ETL workflow by updating and creating new mappers in Scala and Java",
      tags: ["etl-1", "java-1", "scala-1"],
      isVisible: true
    },
    {
      description: "Created internal web applications using Angular and React with Java and SpringBoot for the backend",
      tags: ["javascript-1", "angular-1", "react-1", "nodejs-1", "web_dev-1", "rest-1", "java-2", "spring-1", "springboot-1"],
      isVisible: true
    },
    {
      description: "Developed and managed microservices in containers on AWS EKS",
      tags: ["aws-1", "eks-1", "microservice-2", "containers-1", "docker-1", "kubernetes-1"],
      isVisible: true
    },
    {
      description: "Updated older apps into containers at scale using Docker and Kubernetes",
      tags: ["refactor-1", "containers-2", "kubernetes-2"],
      isVisible: true
    },
    {
      description: "Debugged and improved legacy apps using Spring Boot and TomEE",
      tags: ["java-3", "spring-2", "springboot-2", "tomee-1", "tomcat-1", "apache-1"],
      isVisible: true
    },
    {
      description: "Improved the CICD pipeline by adding Scala Unit testing",
      tags: ["cicd-1", "scala-2", "testing-1"],
      isVisible: true
    },
    {
      description: "Increased overall code coverage to above 90% coverage",
      tags: ["testing-2"],
      isVisible: true
    },
    {
      description: "Created scripts using Bash to automate daily ETL support tasks",
      tags: ["bash-1", "etc-2", "scripting-1"],
      isVisible: true
    },
    {
      description: "Created and maintained documentation on internal Confluence",
      tags: ["devops-2", "documenation-1", "confluence-1", "atlassian-1"],
      isVisible: true
    }],
  isVisible: true
};

let probityJob_two: JobExperience = {
  company: "Probity, Inc.",
  title: "Software Engineer",
  location: "Herndon, VA",
  startDate: "October 2021",
  endDate: "January 2024",
  tasks: [
    {
      description: "Developed a web application for a Cross Domain Solution tool with a Human Review using Node.js, Express.js, Vue.js, and MongoDB",
      tags: ["javascript-1", "web_dev-1", "nodejs-1", "expressjs-1", "vuejs-1", "mongodb-1"],
      isVisible: true
    },
    {
      description: "Optimized APIs and services to enhance customer experiences, employing strong proficiency in front-end technologies like HTML, CSS, and JavaScript",
      tags: ["javascript-2", "api-1", "html-1", "nodejs-1", "web_dev-1", "rest-1", "css-1", "frontend-1"],
      isVisible: true
    },
    {
      description: "Demonstrated expertise in back-end development using Node.js, allowing for seamless integration and improved system performance",
      tags: ["nodejs-2", "backend-1", "performance-1"],
      isVisible: true
    },
    {
      description: "Played a pivotal role in scaling and modernizing backend architecture, ensuring that it met high standards for engineering excellence, scalability, reliability, and reusability",
      tags: ["refactor-1", "architecture-1"],
      isVisible: true
    },
    {
      description: "Improved the CICD process by implementing end to end testing with Cypress",
      tags: ["cicd-1", "cypress-1", "testing-1"],
      isVisible: true
    },
    {
      description: "Developed a desktop application for a Digital Forensics tool using C# and WPF",
      tags: ["desktop_app-1", "c#-1", "wpf-1", "digitalforensics-1"],
      isVisible: true
    },
    {
      description: "Updated SDLC practices within the team to modern trends and practices",
      tags: ["sdlc-1", "team-1"],
      isVisible: true
    },
    {
      description: "Integrated with AWS S3 for inputs for the Digital Forensics application and outputs for the Cross Domain tool",
      tags: ["aws-1", "digitalforensics-2", "s3-1"],
      isVisible: true
    },
    {
      description: "Developed the internal web application for the holiday party raffle site. Modernized the UI and added data visualization tools",
      tags: ["web_dev-2", "ruby-1", "rails-1", "refactor-3", "userinterface-2"],
      isVisible: true
    }],
  isVisible: true
};


let debugJobList: JobExperience[] = [
  probityJob_two, jpmc, solutionsPestAndLawn, probityJob_one, tdaJob
];

export {
  debugJobList
}
"use client";
import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CmdNode from '../CmdNode/CmdNode';

import './CmdBuilder.module.css';

export default function CmdBuilder(props) {
  const [cmdNodes, setCmdNodes] = React.useState([{ id: new Date().getTime(), value: 'git status', enabled: true }]);

  useEffect(() => {
    props.onUpdate(sendCmdNode());
  }, [cmdNodes]);

  function addRow() {
    setCmdNodes(() => cmdNodes.concat(
      {id: new Date().getTime(), value: '', enabled: true}
    ));
  }

  function handleKeyPress(e) {
    if (e.key === "Enter" && e.target.value !== "") {
      addRow();
    }
  }

  function handleMoveUp(id) {
    let newArray = [...cmdNodes];
    let indexOfTargetNode = newArray.map(node => node.id).indexOf(id);
    let oneIndexUp = indexOfTargetNode - 1;
    console.log(oneIndexUp, 0, oneIndexUp < 0)
    if (oneIndexUp > 0) {
      newArray.splice(indexOfTargetNode, 0, newArray.splice(oneIndexUp, 1)[0]);
      setCmdNodes(newArray);
    }
  }

  function handleMoveDown(id) {
    let newArray = [...cmdNodes];
    let indexOfTargetNode = newArray.map(node => node.id).indexOf(id);
    let oneIndexDown = indexOfTargetNode + 1;
    console.log(oneIndexDown, cmdNodes.length, oneIndexDown > cmdNodes.length);
    if (oneIndexDown < cmdNodes.length) {
      newArray.splice(indexOfTargetNode, 0, newArray.splice(oneIndexDown, 1)[0]);
      setCmdNodes(newArray);
    }
  }

  function handleEnableUpdate(id, isEnabled) {
    let updatedArray = cmdNodes.map(node => {
      if (node.id == id) {
        return { ...node, enabled: isEnabled };
      }
      return node;
    });
    setCmdNodes(updatedArray);
  }

  function createFullCmd() {
    let cmd = cmdNodes.filter(node => node.enabled == true).map(node => node.value).join(' ').trim();
    return cmd;
  }

  function removeNode(id) {
    setCmdNodes((nodes) => { return nodes.filter((node) => node.id != id) });
  }

  function updateNodeValue(id, value) {
    let updatedArray = cmdNodes.map(node => {
      if (node.id == id) {
        return { ...node, value: value };
      }
      return node;
    });
    setCmdNodes(updatedArray);
  }

  function sendCmdNode() {
    let cmdObj = {
      title: createFullCmd(),
      nodes: cmdNodes
    }
    return cmdObj;
  }

  function clearCmd() {
    console.log('clear');
    setCmdNodes([]);
  }

  useEffect(() => {
    props.builderFunc.current = clearCmd
  }, [props.builderFunc.current])

  return (
    <div id='cmdBuilder' className='dark-div-3' style={{ backgroundColor: 'inherit'}}>
      <Button onClick={addRow} tabIndex='-1' size='sm'>Add cmd Node</Button>
      {cmdNodes.map(node => (
        <CmdNode className={node} key={node.id} uniqueId={node.id}
          onRemove={(id) => removeNode(id)} value={node.value}
          onUpdate={(id, value) => updateNodeValue(id, value)}
          onKeyPress={e => handleKeyPress(e)}
          onMoveUp={e => handleMoveUp(e)}
          onMoveDown={e => handleMoveDown(e)}
          onEnable={(id, isEnabled) => handleEnableUpdate(id, isEnabled) }
        />
      ))}
    </div>
  )
}
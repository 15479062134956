import Banner from '../components/Banner/Banner';
import { useState, useEffect, Fragment } from 'react';
import { JobExperience, JobTask, debugJob } from '../components/ResumeBuilder/Types';
import { debugJobList } from '../components/ResumeBuilder/MyResumeData';
import '../components/ResumeBuilder/Resume.css';
import { hideJavaTasks, hidePythonTasks, showAllTasks, prioritizePythonTasks, prioritizeJavaTasks } from '../components/ResumeBuilder/ResumeParser';
import { Draggable } from "react-drag-reorder";
import Container from 'react-bootstrap/Container';

export default function ResumeBuilder() {

  let [dJob, setDJob] = useState(debugJob);
  let [dJobList, setDJobList] = useState(debugJobList);

  useEffect(() => {
    console.log("list changed", dJobList);
  }, [dJobList]);

  function buildJobExperienceSection(jobExp: JobExperience, jobId: number) {
    return (
      <Container key={jobId}>
        <section className="text-align-start" style={{ margin: "10px 0" }}>
          <h2>{jobExp.company}</h2>
          <h5 className="employerLocation">{jobExp.location}</h5>
          <h5 className="employeeTitle">{jobExp.title}</h5>
          <ul className="resumeTasks">
              {jobExp.tasks.map((task, idx) => {
                return (
                  <Fragment key={idx}>
                    {task.isVisible && (
                      <li>{task.description}</li>
                    )}
                  </Fragment>
                  )
                ;
              })}
          </ul>
        </section>
        </Container>
    )
  }

  function handleDebugButtonClick() {

  }

  function hideJava() {
    const updatedJobList: JobExperience[] = hideJavaTasks(dJobList);
    setDJobList(updatedJobList);
  }

  function hidePython() {
    const updatedJobList: JobExperience[] = hidePythonTasks(dJobList);
    setDJobList(updatedJobList);
  }

  function showAll() {
    const updatedJobList: JobExperience[] = showAllTasks(dJobList);
    setDJobList(updatedJobList);
  }

  function prioritizePython() {
    const updatedJobList = prioritizePythonTasks(dJobList);
    setDJobList(updatedJobList);
  }

  function prioritizeJava() {
    const updatedJobList = prioritizeJavaTasks(dJobList);
    setDJobList(updatedJobList);
  }

  function handleLogButtonClick() {
    console.log("post-dJobList", dJobList);
  }

  return (
    <>
      <Banner title="Resume Builder" text="Tailor the Resume to the Job Posting" />
      <section>
        <button onClick={hideJava}>Hide Java</button>
        <button onClick={hidePython}>Hide Python</button>
        <button onClick={handleDebugButtonClick}>Debug</button>
        <button onClick={handleLogButtonClick}>Log</button>
        <button onClick={showAll}>Show All</button>
        <button onClick={prioritizePython}>Prioritize Python</button>
        <button onClick={prioritizeJava}>Prioritize Java</button>
      </section>
      <div className="flex-container">
        <div className="column">
          {dJobList.map((job, idJob) => {
            return (
              buildJobExperienceSection(job, idJob)
            )
          })}
        </div>
      </div>
    </>
  )
}
"use client";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Banner from '../components/Banner/Banner';

declare var readMsgFromCanvas: any;
declare var loadIMGtoCanvas: any;
declare var writeMsgToCanvas: any;

export default function Page() {

  useEffect(() => {
    document.title = "ACT: Steganography";
  });

  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); }


  const [initImg, setInitImg] = useState("");
  const handleImgSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setInitImg(URL.createObjectURL(e.target.files[0]));
    }
  }

  const [modalText, setModalText] = useState("Encrypt");
  const showEncrypt = () => {
    setModalText("Encrypt");
    setShow(true);
  }
  const showDecrypt = () => {
    readIMG();
    setModalText("Decrypted Message");
    setShow(true);
  }

  const [decryptedMsg, setDecryptedMsg] = useState("");

  const renderEncryptModal = () => {
    return (
      <Card>
        <Card.Body>
          <Form.Group className="mb-3" controlId="msg">
            <Form.Label>Enter Message to Encrypt</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="primary" onClick={writeIMG}>Encrypt</Button>
          </div>
        </Card.Body>
        <Card.Img variant="top" src="" id="resultImg" />
      </Card>
    )
  }

  const renderDecryptModal = () => {
    return (
      <Card>
        <Card.Body>
          <Card.Text id='result'>{decryptedMsg}</Card.Text>
        </Card.Body>
      </Card>
    )
  }

  const renderImgCard = () => {
    return (
      <section style={{ 'display': 'flex', 'justifyContent': 'center' }}>
        <Card style={{ width: '20rem' }}>
          <Card.Body>
            <Card.Title>Selected Image</Card.Title>
            <Card.Img variant="top" src={initImg} id="resultimg" />
            <div className="d-grid gap-2 m-3">
              <Button variant="primary" onClick={showDecrypt}>Decrypt</Button>
            </div>
            <div className="d-grid gap-2 m-3">
              <Button variant="info" onClick={showEncrypt}>Encrypt</Button>
            </div>
          </Card.Body>
        </Card>
      </section>
    )
  }


  return (
    <>
      <Banner title='Steganography' text='Encrypt/Decrypt a message in an image' />
      <Container>
        <section>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="12">
              <Form.Group as={Row} controlId="file" className="mb-3">
                <Col sm="3">
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Control type="file" accept=".png,.jpg,.jpeg,.webp"
                    onChange={handleImgSelect} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="pass">
                <Form.Label column sm="3">Password</Form.Label>
                <Col sm="9">
                  <Form.Control type="password" placeholder="Password" />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </section>
        {(initImg != '') ? renderImgCard() : <></>}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalText}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(modalText === 'Encrypt') ? renderEncryptModal() : renderDecryptModal()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )

  function readIMG() {

    let resultInfo = document.getElementById('result');

    function readfunc() {
      let pass = document.getElementById('pass');

      let t = readMsgFromCanvas('canvas', (pass as HTMLInputElement)?.value, 0);
      if (t != null) {
        // TODO: Revisit this

        //t = t.split('&').join('&amp;');
        //t = t.split(' ').join('&nbsp;');
        //t = t.split('<').join('&lt;');
        //t = t.split('>').join('&gt;');
        //t = t.replace(/(?:\r\n|\r|\n)/g, '<br />');
        setDecryptedMsg(t);
      } else {
        //resultInfo.innerHTML = 'ERROR REAVEALING MESSAGE!';
      }

    }

    loadIMGtoCanvas('file', 'canvas', readfunc);
  }

  function writeIMG() {
    //console.log('msg', document.getElementById('msg').value);
    let resultImg = document.getElementById('resultImg');
    (resultImg as HTMLImageElement).setAttribute('src', '');

    //let resultInfo = document.getElementById('result');
    //resultInfo.innerHTML = 'Processing...';
    function writefunc() {

      let msg = document.getElementById('msg');
      let pass = document.getElementById('pass');

      var t = writeMsgToCanvas('canvas', (msg as HTMLInputElement).value, (pass as HTMLInputElement).value, 0);
      if (t === true) {
        let myCanvas = document.getElementById("canvas");
        let image = (myCanvas as HTMLCanvasElement).toDataURL("image/png");
        (resultImg as HTMLImageElement).setAttribute('src', image);
        //resultInfo.innerHTML = 'Success! Save the result image below and send it to others!';
        //showElement(resultImg);
        //showImgResult();
      } else {
        //resultInfo.innerHTML = t;
      }
    }

    loadIMGtoCanvas('file', 'canvas', writefunc, 500);
  }
}
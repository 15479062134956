import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import Banner from '../components/Banner/Banner';
import CmdBuilder from '../components/CmdBuilder/CmdBuilder';
import CmdSavedList from '../components/CmdSavedList/CmdSavedList';

export default function BuildCmd(){

  useEffect(() => {
    document.title = "ACT: cmd Center - Build and Store cmds";
  });

  const myRef = useRef<any>();
  const builderRef = useRef<any>();

  const [isVisible, setIsVisible] = React.useState(false);
  const [fullCmd, setFullCmd] = React.useState('git status');
  const [cmdNodes, setCmdNodes] = React.useState([]);

  function sendSavedCmd() {
    let savedCmd = {
      title: fullCmd,
      nodes: cmdNodes
    }
    return savedCmd;
  }

  function copyCmdToClipboard() {
    navigator.clipboard.writeText(fullCmd);
    setShow(true);
  }

  function clearCmd() {
    builderRef.current();
  }

  function handleUpdate(title: string, nodes: any) {
    setFullCmd(title);
    setCmdNodes(nodes);
  }

  const [show, setShow] = useState(false);

  return (
    <>
      <Banner title='CMD Builder' text='Write and re-arrange nodes to build a full CMD' />
      <Container>
        <section style={{ marginBottom: "2vh" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'inherit' }}>
            <Toast onClose={() => setShow(false)} show={show} delay={2000} bg='success' autohide>
              <Toast.Body>Copied to clipboard</Toast.Body>
            </Toast>
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <div style={{ border: 'none', borderRadius: '12px', padding: '1rem 1rem', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={copyCmdToClipboard}>
                    <div style={{ width: '90%' }}>{fullCmd}</div>
                    <div>
                      <Button onClick={() => myRef.current()} tabIndex={-1} size='sm' style={{ marginRight: '1rem' }}><i className="bi bi-floppy2"></i></Button>
                      <Button onClick={() => clearCmd()} variant='outline-danger' tabIndex={-1} size='sm'><i className="bi bi-trash3-fill"></i></Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 4, offset: 7 }}>
                </Col>
                <Col xs={{ span: 1 }}>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <CmdBuilder builderFunc={builderRef} onUpdate={ (value: React.SetStateAction<any>) => handleUpdate(value.title, value.nodes) } />

        </section>
        <CmdSavedList childFunc={myRef} sendSavedCmd={sendSavedCmd} />
      </Container>
    </>
  )
}
import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ChangeEvent } from 'react';
import Banner from '../components/Banner/Banner';

const openpgp = require("openpgp");
const publicKeyArmored = `-----BEGIN PGP PUBLIC KEY BLOCK-----

xjMEZa6q4xYJKwYBBAHaRw8BAQdAgIACNnQPoATXNfjESY8Tqc+oaOI1WwBL
Frc4sBMLcojNJkFkcmlhbiBDb3JwdXogPGFkcmlhbmNvcnB1ekBnbWFpbC5j
b20+wowEEBYKAD4FgmWuquMECwkHCAmQFNb/4KugpWADFQgKBBYAAgECGQEC
mwMCHgEWIQTqi70ePeH8EKUHf5UU1v/gq6ClYAAAwigA+gJ7cVkUz2xvb60P
LB/Z8oTlGQB3teZwxamrPDWoDmh5AQCC1t2FKM5a8NpGlHoaRdROXeG5hGjI
KOw+BBWNEUV7B844BGWuquMSCisGAQQBl1UBBQEBB0BGsoL+Ay1lIGA3dTE/
IYCDgs3N+PYK66prfPH/b17hJAMBCAfCeAQYFggAKgWCZa6q4wmQFNb/4Kug
pWACmwwWIQTqi70ePeH8EKUHf5UU1v/gq6ClYAAARokA/3pIXugzUVlpUqdW
0Yfz42FhdGLQL6DNLTzv3JW4I57lAP0dYkGcP9C1m+QgQEBQFXwVFrjWNq0g
7PNKH5RGD43dDg==
=0TKi
-----END PGP PUBLIC KEY BLOCK-----
`;

export default function PGP() {
  useEffect(() => {
    document.title = "ACT: PGP ME";
  });

  const [show, setShow] = useState(false);
  const [encryptedMsg, setEncryptedMsg] = useState("");
  const [plainMsg, setPlainMsg] = useState("debug");

  const handleClose = () => { setShow(false); }

  const handleEncrypt = async () => {
    const plainData = plainMsg;
    const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });
    const message = await openpgp.createMessage({
      text: plainData
    });
    const encrypted = await openpgp.encrypt({
      message: message,
      encryptionKeys: publicKey,
    });

    let formattedEncrypted = encrypted.split("\n").join("\u00b7");

    console.log('formatted', encrypted);

    setEncryptedMsg(encrypted);
  }

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target } = e;
    let msgValue = (target as HTMLTextAreaElement).value;
    setPlainMsg(msgValue);
  }

  const renderResults = () => {
    return (
      <section>
        <p style={{ 'whiteSpace': 'pre-wrap' }}>{encryptedMsg}</p>
      </section>
    )
  }

  return (
    <>
      <Banner title='Pretty Good Privacy' text='Encrypt a message with my public key' />
      <Container>
        <section>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="12">
              <Form.Group as={Row} className="mb-3" controlId="pass">
                <Col sm="12">
                  <Form.Control as="textarea" rows={10} placeholder="" onChange={handleMessageChange} />
                </Col>
              </Form.Group>
              <div className="d-grid gap-2">
                <Button variant="primary" onClick={handleEncrypt}>Encrypt</Button>
              </div>
            </Col>
          </Row>
        </section>
        { (encryptedMsg != "") ? renderResults() : <></>}
      </Container>
    </>
  )
}
import ToDoList from '../components/ToDoList/ToDoList';
import Banner from '../components/Banner/Banner';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { fetchShortUrl, ShortUrlJsonBody } from '../service/FetchService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export default function ToDo() {

  const [isResultVisible, setIsResultVisible] = useState(false);
  const [isUrlVisible, setIsUrlVisible] = useState(false);
  const [resultText, setResultText] = useState('');
  const [urlText, setUrlText] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [targetUrl, setTargetUrl] = useState('');
  const [apiKey, setApiKey] = useState("");

  function toggleResultVisibility() {
    return isResultVisible ? "block" : "none";
  }

  function toggleUrlVisibility() {
    return isUrlVisible ? "block" : "none";
  }

  async function submitForm() {
    console.log("hello");

    const fetchBody = {
      url_id: shortUrl,
      url: targetUrl,
      owner: "website"
    }
    let fetchResult;
    try {
      fetchResult = await fetchShortUrl(JSON.stringify(fetchBody), apiKey);
      const fetchJson = await fetchResult?.json();
      if (fetchJson.error) {
        const errorMsg = `Error Creating Url: ${fetchJson.message}`
        setResultText(errorMsg);
        setIsUrlVisible(false);
      } else {
        setResultText(`Success!`);
        setUrlText(`https://ajon.link/to/${fetchJson.url_id}`);
        setIsUrlVisible(true);
      }
    } catch (e) {
      const errorMsg = `Error Fetching Data: ${e}`;
      setResultText(errorMsg);
      setUrlText('');
      setIsUrlVisible(false);
    } finally {
      setIsResultVisible(true);
    }
  }

  return (
    <>
      <Banner title="Url Shortener" text="Take a long URL and shorten it." />
      <Container>
        <section>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="8">
              <Form.Group as={Row} controlId="file" className="mb-3">
                <Col sm="3">
                  <Form.Label>API Key</Form.Label>
                </Col>
                <Col sm="9">
                  <Form.Control type="password" placeholder="API Key" value={apiKey} onChange={(event) => setApiKey(event.target.value)}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="password">
                <Form.Label column sm="3">Short Url</Form.Label>
                <Col sm="9">
                  <Form.Control type="text" value={shortUrl} placeholder="your-short-url" onChange={(event) => setShortUrl(event.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="targetUrl">
                <Form.Label column sm="3">Target Url</Form.Label>
                <Col sm="9">
                  <Form.Control type="textarea" value={targetUrl} onChange={(event) => setTargetUrl(event.target.value)} />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <button onClick={submitForm}>Submit</button>
        </section>
        <section id="urlResult" style={{ display: toggleResultVisibility() }}>
          {resultText}
          <a style={{ display: toggleUrlVisibility() }} href={urlText}>{urlText}</a>
        </section>
      </Container>
    </>
  )
}
"use client";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Banner from '../components/Banner/Banner';

declare var readMsgFromCanvas: any;
declare var loadIMGtoCanvas: any;
declare var writeMsgToCanvas: any;

export default function Page() {

  useEffect(() => {
    document.title = "ACT: Cesarian Shift";
  });

  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); }


  const [alphabet, setAlphabet] = useState("abcdefghijklmnopqrstuvwxyz");

  const [alphabetIsDisabled, setAlphabetIsDisabled] = useState(true);

  const handleAlphabetToggle = () => {
    setAlphabetIsDisabled(!alphabetIsDisabled);
  };

  const handleAlphabetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlphabet(event.target.value.trim());
  };

  const [shift, setShift] = useState(0);

  const [shiftAlphabet, setShiftAlphabet] = useState("abcdefghijklmnopqrstuvwxyz");

  const [isEncryptMode, setIsEncryptMode] = useState(true);

  const handleShiftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue: number = parseInt(event.target.value);


    setShift(inputValue);
  };

  const handleModeChange = () => {
    setIsEncryptMode(!isEncryptMode);
  };

  useEffect(() => {
    const modeText = (isEncryptMode) ? "Encrypt" : "Decrypt";
    const labelElement: HTMLElement | null = document.querySelector('label[for="encrypt-switch"]');
    if (labelElement) {
      labelElement.innerText = modeText;
    }
  }, [isEncryptMode]);

  useEffect(() => {
    const splitAlphabet = alphabet.split('');
    let shiftedAlpha = [...splitAlphabet];
    for (let i = 0; i < shift; i++) {
      let charToShift = shiftedAlpha.shift();
      if (charToShift) {
        shiftedAlpha.push(charToShift);
      }
    }
    setShiftAlphabet(shiftedAlpha.join(''));
  }, [shift])

  const [output, setOutput] = useState("");
  const [input, setInput] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value.toLowerCase();
    setInput(input);

  };

  useEffect(() => {
    updateOutput();
  }, [input, shiftAlphabet, isEncryptMode])

  function updateOutput() {
    let sourceAlphabet = (isEncryptMode) ? alphabet : shiftAlphabet;
    let targetAlphabet = (isEncryptMode) ? shiftAlphabet : alphabet;
    
    let inputSplit = input.split('');

    let outputArray: string[] = [];
    let sourceAlphabetArray = sourceAlphabet.split('');
    let targetAlphaArray = targetAlphabet.split('');
    inputSplit.forEach(c => {
      let alphaIndex = sourceAlphabetArray.indexOf(c);
      if (alphaIndex == -1) {
        outputArray.push(c);
      } else {
        outputArray.push(targetAlphaArray[alphaIndex]);
      }
    });
    let output = outputArray.join('');
    setOutput(output);
  }


  return (
    <>
      <Banner title='Cesarian Shift' text='Encrypt/Decrypt a message with the Cesarian Shift' />
      <Container>
        <section>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="12">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Alphabet</Form.Label>
                <Col sm="6">
                  <Form.Control type="text" placeholder="abcdefghijklmnopqrstuvwxyz" value={alphabet} onChange={handleAlphabetChange} disabled={alphabetIsDisabled}/>
                </Col>
                <Col sm="2">
                  <Form.Check type="switch" id="custom-switch" checked={alphabetIsDisabled} onChange={handleAlphabetToggle} label="Toggle Edit"/>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </section>
      </Container>
      <Container>
        <section>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="12">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Shift</Form.Label>
                <Col sm="1">
                  <Form.Control type="number" id="n-shift" max={alphabet.length} min="0" value={shift} onChange={handleShiftChange}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Mode</Form.Label>
                <Col sm="1">
                  <Form.Check type="switch" id="encrypt-switch" checked={isEncryptMode} onChange={handleModeChange} label="Encrypt" />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="12">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Input</Form.Label>
                <Col sm="9">
                  <Form.Control as="textarea" rows={3} id="input-cesar" onChange={handleInputChange}/>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </section>
      </Container>
      <Container>
        <section>
          <Row style={{ backgroundColor: 'inherit' }}>
            <Col sm="12">
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">Output</Form.Label>
                <Col sm="9">
                  <Form.Control as="textarea" rows={3} id="output-cesar" value={output} readOnly/>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  )

}
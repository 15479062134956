"use client";
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import styles from './CmdNode.module.css';

export default function CmdNode(props) {

  const [isEnabled, setIsEnabled] = React.useState(true);

  function handleSwitchChange() {
    let newValue = !isEnabled;
    setIsEnabled(newValue);
    props.onEnable(props.uniqueId, newValue)
  }

  function handleKeyPress(event) {
    props.onKeyPress(event);
  }

  return (
    <div className={styles.nodeRow}>
      <Row className="align-items-center">
        <Col xs={6}>
          <Form.Control autoFocus
            type="text"
            aria-describedby="cmdNodeHelpBlock"
            onChange={(event) => props.onUpdate(props.uniqueId, event.target.value)}
            onKeyPress={(event) => handleKeyPress(event)}
            disabled={!isEnabled}
              />
        </Col>
        <Col xs={1}>
           <Form.Check
            type="switch"
            defaultChecked={isEnabled}
            onChange={handleSwitchChange}
            tabIndex="-1"
            />
        </Col>
        <Col xs={2}>
          <Button size='sm'
            onClick={() => props.onMoveUp(props.uniqueId)}
            variant="outline-warning" tabIndex="-1" tooltip="hi"
          ><i className="bi bi-caret-up-fill"></i></Button>
          <Button size='sm'
            onClick={() => props.onMoveDown(props.uniqueId)}
            variant="outline-warning" tabIndex="-1"
          ><i className="bi bi-caret-down-fill"></i></Button>
        </Col>
        <Col xs={{span:1,offset:2}}>
          <Button size='sm'
            onClick={() => props.onRemove(props.uniqueId)}
            variant="outline-danger" tabIndex="-1"
          ><i className="bi bi-trash3-fill"/></Button>
        </Col>
      </Row>
    </div>
  )
}
import './Login.css';
import { useState, useEffect, useRef } from 'react';
import { getUser, getToken, setUserSession, resetUserSession } from '../../service/AuthService';
import { login } from '../../service/FetchService';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';

export default function Login() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const usernameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  async function handleLoginButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (username.trim() == '' || password.trim() == '') {
      showErrorAlert('Username and Password are empty.');
      return;
    }

    const requestBody = {
      username: username,
      password: password
    };

    showLoadingAlert();
    const fetchLogin = await login(JSON.stringify(requestBody));
    const json = await fetchLogin.json();
    if (json.error) {
      showErrorAlert(json.message);
    } else {
      showSuccessAlert();
      setUserSession(json.user, json.token);
      navigate(0);
    }
  }

  const [alertVariant, setAlertVariant] = useState('success');
  const [alertText, setAlertText] = useState("Please wait.");
  const [alertIsVisible, setAlertIsVisible] = useState(false);

  function showErrorAlert(errorText: string) {
    setAlertVariant("danger");
    setAlertText(errorText);
    setAlertIsVisible(true);
  }

  function showSuccessAlert() {
    setAlertVariant("success");
    setAlertText("Login Successful. Please wait.");
    setAlertIsVisible(true);
  }

  function showLoadingAlert() {
    setAlertVariant("primary");
    setAlertText("Please wait.");
    setAlertIsVisible(true);
  }

  function hideAlert() {
    setAlertVariant("success");
    setAlertText("");
    setAlertIsVisible(false);
  }

  function toggleAlertVisibilityStyle(): "visible" | "hidden" {
    const style = alertIsVisible ? "visible" : "hidden";
    return style
  }

  return (
    <>
    <section id="loginSection">
      <Form>
        <Row style={{ backgroundColor: 'inherit' }}>
          <Col sm="12">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">Username</Form.Label>
              <Col sm="9">
                <Form.Control type="text" ref={usernameRef} value={username} onChange={event => setUsername(event.target.value)} />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ backgroundColor: 'inherit' }}>
          <Col sm="12">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="3">Password</Form.Label>
              <Col sm="9">
                <Form.Control type="password" value={password} onChange={event => setPassword(event.target.value)} />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-grid gap-2">
          <button type="submit" onClick={handleLoginButtonClick}>
            Login
          </button>
        </div>
      </Form>
    </section>
    <div className="mt-5" style={{ visibility: toggleAlertVisibilityStyle() }}>
      <Alert id="floatingAlert"  variant={alertVariant}>{alertText}</Alert>
    </div>
    </>
  )
}
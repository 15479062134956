import './Banner.css';
import Container from 'react-bootstrap/Container';

type Props = {
  title: string,
  text: string
}

export default function Banner(props: Props) {

  return (
    <>
      <Container className='act-banner'>
        <div>
          <h2>{props.title}</h2>
          <h5 id='act-banner-text' style={{}}>{props.text}</h5>
        </div>
      </Container>
    </>
  )
}
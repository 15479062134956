import './Button.css';

interface Props {
  text: string,
  type: ButtonType
  click?: ()=>void,
}

export enum ButtonType {
  HEADER,
  SUCCESS,
  PRIMARY,
  SECONDARY,
  WARNING,
  DANGER,
}

function assignHeader(btnType: ButtonType){
  switch(btnType){
    case ButtonType.HEADER:
      return "headerBtn";
    case ButtonType.SUCCESS:
      return "successBtn";
    case ButtonType.PRIMARY:
      return "primaryBtn";
    case ButtonType.SECONDARY:
      return "secondaryBtn";
    case ButtonType.WARNING:
      return "warningBtn";
    case ButtonType.DANGER:
      return "dangerBtn";
    default:
      return "";
  }
}

export default function Button(props: Props){
  return (
    <button onClick={props.click} className={assignHeader(props.type)}>{props.text}</button>
  )
}
"use client";
import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CmdSaved from '../CmdSaved/CmdSaved';

import styles from './CmdSavedList.module.css';

interface cmdSavedListProps {
  sendSavedCmd: any,
  childFunc: any
}

export default function CmdSavedList(props: cmdSavedListProps) {

  const exampleCmd = [{ "key": 1696541785504, "title": "git status", "enabled": true }];

  const [savedCmds, setSavedCmds] = React.useState<Array<any>>([]);
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLoadCmds = () => {
    setSavedCmds(exampleCmd);
    console.log(savedCmds);
  };

  const emptyCmdsJsx = (<span>No cmds saved.</span>);

  function saveCmd() {
    let newArray = [...savedCmds];
    let newValue = props.sendSavedCmd();
    newValue.key = new Date().getTime();
    newArray.push(newValue);
    setSavedCmds(newArray);
  }

/*  function saveAllCmdsJson() {
    console.log('debug', savedCmds);
  }*/

  function handleDeleteSavedCmd(title: string) {
    let newArr = savedCmds.filter(cmd => cmd.title !== title);
    setSavedCmds(newArr);
  }

  useEffect(() => {
    props.childFunc.current = saveCmd
  })

  return (
    <section id={styles["savedCmdList"]}>
      <Row style={{ backgroundColor: 'inherit' }}>
        <Col xs={8}>
          <h2>Stored cmds {(savedCmds.length > 0) ? (<Button variant='secondary' /*onClick={saveAllCmdsJson()}*/ tabIndex={-1} size='sm'>Save JSON</Button>) : <></>}</h2>
        </Col>
        <Col xs={{ span: 2, offset: 2 }}>
          <Button variant='secondary' tabIndex={-1} size='sm' onClick={handleShow}>Load cmds</Button>
        </Col>
      </Row>
      {(savedCmds.length > 0) ? savedCmds.map(cmd =>
        (
          <CmdSaved key={savedCmds.indexOf(cmd)} title={cmd.title} onDeleteSavedCmd={ (title: string) => handleDeleteSavedCmd(title) }/>)
        )
        : emptyCmdsJsx}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>cmd Loader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Add a JSON array of cmd Objects then click Save</p>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control as="textarea" rows={3} placeholder={'Example: ' + JSON.stringify(exampleCmd)}/>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLoadCmds}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  )
}
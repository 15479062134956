export type JobExperience = {
  company: string,
  title: string,
  location: string,
  startDate: string,
  endDate: string,
  tasks: JobTask[],
  isVisible: boolean
}

export type JobTask = {
  description: string,
  tags?: string[],
  isVisible: boolean
}

let debugJob: JobExperience = {
  company: "Remote Job",
  title: "Software Engineer",
  location: "Remote",
  startDate: "startDate",
  endDate: "endDate",
  tasks: [
    {
      description: "Managed and maintained builds and releases",
      isVisible: true
    },
    {
      description: "Implemented an internal test API using Python and Flask - py-3",
      tags: ["python-3"],
      isVisible: true
    },
    {
      description: "Made a website with Django - py-2",
      tags: ["python-2"],
      isVisible: true
    },
    {
      description: "Created ML to study housing prices - py-1",
      tags: ["python-1"],
      isVisible: true
    },
    {
      description: "Researched and implemented new technologies into the application",
      isVisible: true
    },
    {
      description: "Java Stuff",
      tags: ['java-4'],
      isVisible: true
    },
    {
      description: "Added new UI features with various JSPs using HTML, JavaScript, CSS, and JSTL",
      tags: ['java-1'],
      isVisible: true
    },
    {
      description: "Managed and deployed builds to JBoss and Tomcat servers on local, test, and live sites",
      tags: ['java-2'],
      isVisible: true
    }],
  isVisible: true
}

let debugJob2: JobExperience = JSON.parse(JSON.stringify(debugJob));
  debugJob2.company = "Remote Job Two";

let debugJobList: JobExperience[] = [
  debugJob, debugJob2
];

export {
  debugJob,
  debugJobList
}
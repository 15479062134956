import { useState, useEffect } from 'react';
import { getUser, getToken, setUserSession, resetUserSession } from '../service/AuthService';
import { verify } from '../service/FetchService';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Login from '../components/Login/Login';

export default function Home() {
  const [hasValidToken, setHasValidToken] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    if (token === 'undefined' || token === undefined || token === null || !token) {
      setHasValidToken(false);
      setIsVisible(true);
      return;
    }
    const user = getUser();
    const requestBody = {
      user: user,
      token: token
    };

    const fetchVerify = async () => {
      const response = await verify(JSON.stringify(requestBody));
      if (!response.ok) {
        setHasValidToken(false);
        resetUserSession();
        navigate(0);
      }
      const json = await response.json();
      setUserSession(json.user, json.token);
      setHasValidToken(true);
      setIsVisible(true);
    };

    fetchVerify();
  }, []);

  function renderLogin() {
    return (
      <div className="frontPage">
        <Container id="loginContainer">
          <Login />
        </Container>
      </div>
    )
  }

  return (
    <>
      {(!hasValidToken && isVisible) ? renderLogin() : <></>}
    </>
  )
}